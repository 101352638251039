<template>
    <div v-if="show_alert == '1'" class="m-1 rounded p-1 text-start" style="border: 1px solid #3D2BB1;">
        <router-link
            v-if="1 || num_alerts"
            :to="{
                name: 'Alerts',
                params: {
                    overrideFilter: {
                        sort: {},
                        search: {},
                        filter: { alert_type: [alert_type] },
                        page: { num_per_page: 10, current_page: 1, page_num: 1 },
                        column: []
                    },
                    show_all_alerts:this.all_alerts
                }
            }"
            class="alerts_details"
        >
            <div :class="classes" :style="alertMessageStyle">
                <span>{{ ucwords(alert_type) }}</span>
                <div class="num-alerts">{{ num_alerts }}</div>
            </div>
        </router-link>
        <div v-else class="alerts_details">
            <div :class="classes" :style="alertMessageStyle">
                <span>{{ ucwords(alert_type) }}</span>
                <div class="num-alerts">{{ num_alerts }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    /*
    this has lots of things
    each alert type may be different
     */

    import { show_alert_prefix } from '@/util/globalConstants';

    export default {
        name: 'DashAlert',
        props: {
            alert_type: {
                Type: String,
                Default: 'signature'
            },
            all_alerts: {
                Type: [String, Number],
                Default: 0
            }
        },
        data() {
            return {
                num_alerts: 0,
                show_alert: 0,
                classes: ' dash-alert-div d-flex justify-content-between alert-font ',
                alertMessageStyle: 'font-weight:600; color: #3D2BB1'
            };
        },
        methods: {
            async load_data() {
                let level_id = this.$store.state.user.id;

                const res1 = await this.$api.get(
                    `/settings/setting/show_alert_${this.alert_type}/user/${level_id}`
                );
                this.show_alert = res1.data;

                const res2 = await this.$api.post(`/alerts/count/${this.alert_type}`, {all_alerts:this.all_alerts});
                this.num_alerts = res2.data;

                if (this.num_alerts) this.classes += ' red ';
            },
            async init() {
                this.load_data()
            },
            ucwords(str) {
                str = str.toString().replaceAll('_', ' ');
                return (str + '').replace(/^([a-z])|\s+([a-z])/g, function($1) {
                    return $1.toUpperCase();
                });
            }
        },
        async created() {
            await this.init();
        }
    };
</script>

<style>
@media only screen and (max-width: 1500px) {
    .alert-font {
        font-size: 10px;
    }
}

@media only screen and (min-width: 1500px) {
    .alert-font {
        font-size: 13px;
    }
}
</style>